<template>
  <el-form
    size="small"
    ref="editForm"
    label-position="top"
    :model="scale"
    :rules="rules"
  >
    <el-form-item label="Целевые значения">
      <el-row type="flex" justify="space-between">
        <el-col :span="7">
          <el-form-item prop="target_a">
            Min
            <el-input
              type="textarea"
              :autosize="{ minRows: 4}"
              v-model="scale.target_a"
              :disabled="true"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item prop="target_b">
            Target
            <el-input
              type="textarea"
              :autosize="{ minRows: 4}"
              v-model="scale.target_b"
              :disabled="true"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item prop="target_c">
            Max
            <el-input
              type="textarea"
              :autosize="{ minRows: 4}"
              v-model="scale.target_c"
              :disabled="true"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item
      label="Выплаты за достижения, %"
    >
      <el-row type="flex" justify="space-between">
        <el-col :span="7">
          <el-form-item prop="pay_a">
            <el-input type="number" v-model="scale.pay_a" :disabled="!fieldsPermissions['scale.pays']">
              <template slot="prepend">Min</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item prop="pay_b">
            <el-input type="number" v-model="scale.pay_b" :disabled="!fieldsPermissions['scale.pays']">
              <template slot="prepend">Target</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item prop="pay_c">
            <el-input type="number" v-model="scale.pay_c" :disabled="!fieldsPermissions['scale.pays']">
              <template slot="prepend">Max</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form-item>
  </el-form>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";
import {mapGetters} from "vuex";

export default {
  name: "card-edit-form",
  mixins: [dynamicScaleComponent],
  components: {},

  props: {},

  computed: {},

  watch: {},

  mounted() {
    this.$nextTick(function () {
      this.$refs.editForm.clearValidate();
    })
  },

  data() {


    return {
      scale:{
        target_a: null,
        target_b: null,
        target_c: null,
        pay_a: null,
        pay_b: null,
        pay_c: null,
        fact: null,
      },

      rules: {}

    }
  },

  methods: {

  }
}
</script>

<style>

</style>